import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class CallForTenderService extends BaseService<any> {
  public constructor() {
    super('services/serviceauttech/api/call-for-tender');
  }

  public getCallForTenderCombobox(): Promise<any> {
    return axios.get('services/serviceauttech/api/call-for-tender/combobox');
  }
}
