import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class FundingInstrumentsService extends BaseService<any> {
  public constructor() {
    super('services/serviceauttech/api/funding-instruments');
  }

  public getOptions(): Promise<any> {
    return axios.get(this.endpointName + '/combobox');
  }
}
