// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import App from './app.vue';
import VSwatches from 'vue3-swatches';
import Vue3TouchEvents from 'vue3-touch-events';
import mitt from 'mitt';
import resize from 'vue3-resize-directive';
import { FilterService } from 'primevue/api';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createApp } from 'vue';
import { initPrimeVue } from '@/config/config-primevue';
import { initRouter } from '@/router';
import { reactive } from 'vue';
import { watch } from 'vue';

import AccountService from '@/account/account.service';
import ActorService from '@/project/services/actor.service';
import AudienceService from '@/project/services/audience.service';
import CallForTenderAdminService from '@/project/services/call-for-tender-admin.service';
import CallForTenderService from '@/project/services/call-for-tender.service';
import ChangePasswordService from '@/account/change-password/change-password.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import FundingInstrumentsService from '@/project/services/funding-instruments.service';
import GatewayService from '@/admin/gateway/gateway.service';
import HealthService from '@/admin/health/health.service';
import LoginService from '@/account/login.service';
import LogsService from '@/admin/logs/logs.service';
import MethodService from '@/project/services/method.service';
import MetricsService from '@/admin/metrics/metrics.service';
import ProblemService from '@/project/services/problem.service';
import ProgramSettingService from '@/project/services/program-settings.service';
import ProjectService from '@/project/services/project.service';
import ProviderService from '@/admin/user-management/provider.service';
import QuestionAdminService from '@/project/services/question-admin.service';
import QuestionDetailAdminService from '@/project/services/question-detail-admin.service';
import QuestionDetailService from '@/project/services/question-detail.service';
import QuestionnaireInterpretationService from '@/project/services/questionnaire-interpretation.service';
import QuestionnaireQuestionService from '@/project/services/questionnaire-question.service';
import QuestionnaireService from '@/project/services/questionnaire.service';
import QuestionService from '@/project/services/question.service';
import SchemaService from '@/admin/tenant-management/schema-service';
import SolutionService from '@/project/services/solution.service';
import SpatialContextService from '@/project/services/spatial-context.service';
import StakeholderGroupService from '@/project/services/stakeholder-group.service';
import StateService from '@/project/services/state.service';
import TenantMasterService from '@/admin/tenant-management/tenant-master.service';
import ToastService from 'primevue/toastservice';
import TranslationService from '@/locale/translation.service';
import UserManagementService from '@/admin/user-management/user-management.service';

import { AlertService } from '@rednex/gateway_core';
import { AlertServiceConfiguration } from '@rednex/gateway_core';
import { JhiSortIndicator } from '@rednex/gateway_core';
import { getGlobalProps } from '@rednex/gateway_core';
import { initFortAwesome } from '@rednex/gateway_core';
import { initI18N } from '@rednex/gateway_core';
import { initVueXStore } from '@rednex/gateway_core';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import '../content/scss/global.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import '@rednex/gateway_core/componentstyles';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';
import 'vue3-swatches/dist/style.css';

const emitter = mitt();
const globalProps = getGlobalProps();
const i18n = initI18N();
const router = initRouter();
const store = initVueXStore();

const translationService = new TranslationService();
const accountService = new AccountService(store, router);
const alertService = new AlertService(new AlertServiceConfiguration(false, 0, 5000, 5000, 10000));

router.beforeEach(async (to, from, next) => {
  const currentLanguage = store.getters.currentLanguage;
  await translationService.refreshTranslation(i18n, app.config.globalProperties?.$primevue, currentLanguage);

  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && (to.meta.authorities as []).length > 0) {
    await accountService
      .reloadAuthentication(to.meta.authorities)
      .then(authenticated => {
        if (!authenticated) {
          sessionStorage.setItem('requested-url', to.fullPath);

          if (to.fullPath !== '' && to.fullPath !== '/') {
            next('/forbidden');
          } else {
            next();
          }
        } else {
          next();
        }
      })
      .catch(error => {
        alertService.showHttpError(globalProps, error);
      });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
export const app = createApp({
  el: '#app',
  components: { App } as any,
  template: '<App/>',
  provide: {
    accountService: accountService,
    actorService: new ActorService(),
    alertService: alertService,
    audienceService: new AudienceService(),
    callForTenderAdminService: new CallForTenderAdminService(),
    callForTenderService: new CallForTenderService(),
    changePasswordService: new ChangePasswordService(),
    configurationService: new ConfigurationService(),
    fundingInstrumentsService: new FundingInstrumentsService(),
    gatewayService: new GatewayService(),
    healthService: new HealthService(),
    loginService: new LoginService(),
    logsService: new LogsService(),
    methodService: new MethodService(),
    metricsService: new MetricsService(),
    problemService: new ProblemService(),
    programSettingService: new ProgramSettingService(),
    projectService: new ProjectService(),
    providerService: new ProviderService(),
    questionAdminService: new QuestionAdminService(),
    questionDetailAdminService: new QuestionDetailAdminService(),
    questionDetailService: new QuestionDetailService(),
    questionnaireInterpretationService: new QuestionnaireInterpretationService(),
    questionnaireQuestionService: new QuestionnaireQuestionService(),
    questionnaireService: new QuestionnaireService(),
    questionService: new QuestionService(),
    schemaService: new SchemaService(),
    solutionService: new SolutionService(),
    spatialContextService: new SpatialContextService(),
    stakeholderGroupService: new StakeholderGroupService(),
    stateService: new StateService(),
    tenantMasterService: new TenantMasterService(),
    translationService: translationService,
    userManagementService: new UserManagementService(),
  },
});

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.router = router;
app.config.globalProperties.$appState = reactive({ themeMode: 'light' });

app.use(ToastService);
app.use(VSwatches);
app.use(Vue3TouchEvents);
app.use(i18n);
app.use(router);
app.use(store);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('jhi-sort-indicator', JhiSortIndicator);

app.directive('resize', resize);

initFortAwesome();
initPrimeVue(app);

FilterService.register('DATEEQUALS', (value, filter): boolean => {
  if (filter === undefined || filter === null) {
    return true;
  }
  // test
  if (value === undefined || value === null) {
    return false;
  }

  return new Date(value).toLocaleDateString() === filter.toLocaleDateString();
});

FilterService.register('DEFAULTMULTIPLE', (value, filterArray): boolean => {
  if (filterArray === undefined || filterArray === null || filterArray.length === 0) {
    return true;
  }

  const selected = Array.from(filterArray, data => {
    return (data as any).value.toString();
  });

  const valueArray = [];
  if (value instanceof Array) {
    for (const val of value) {
      valueArray.push(val.name);
    }
  } else {
    valueArray.push(value);
  }

  return valueArray.some(val => selected.includes(val));
});

FilterService.register('DATERANGE', (value, filter): boolean => {
  if (filter === undefined || filter === null) {
    return true;
  }
  // test
  if (value === undefined || value === null) {
    return false;
  }

  const startDate = new Date(filter[0]).toLocaleDateString();
  const dateToFilter = new Date(value).toLocaleDateString();

  if (!filter[1]) {
    return dateToFilter === startDate;
  } else {
    return dateToFilter >= startDate && dateToFilter <= new Date(filter[1]).toLocaleDateString();
  }
});

FilterService.register('BOOLEANBADGECHECK', (value, filterArray): boolean => {
  if (filterArray === undefined || filterArray === null || filterArray.length === 0) {
    return true;
  }

  const selected = Array.from(filterArray, data => {
    return (data as any).value.toString();
  });

  return selected.includes(value.toString());
});

setupAxiosInterceptors(
  unauthenticatedError => {
    const error = unauthenticatedError;
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;
    if (status === 401) {
      // Store logged out state.
      if (!url.equals('/')) {
        store.commit('logout');
      }

      if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
        // Ask for a new authentication
        router.push('/');
        return;
      }
    }
    console.error('unauthenticatedError:', error);
    return Promise.reject(error);
  },
  badRequestError => {
    console.error('badRequestError:', badRequestError);
    return Promise.reject(badRequestError);
  },
  internalServerError => {
    console.error('internalServerError:', internalServerError);
    return Promise.reject(internalServerError);
  },
);

watch(
  () => {
    return store.getters.currentLanguage;
  },
  currentLanguage => {
    translationService.refreshTranslation(i18n, app.config.globalProperties?.$primevue, currentLanguage);
  },
);

app.mount('#app');
