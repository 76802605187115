import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class ProjectService extends BaseService<any> {
  public constructor() {
    super('services/serviceauttech/api/project');
  }

  public getCSV(filterParam?: string): Promise<any> {
    return axios.get(this.endpointName + '/download' + filterParam, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/csv',
      },
    });
  }

  public getRadarChartData(): Promise<any> {
    return axios.get(this.endpointName + '/radarchart');
  }

  public getOptions(projectId: string): Promise<any> {
    return axios.get(this.endpointName + '/combobox/' + projectId);
  }
}
