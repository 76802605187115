import { BaseService } from '@/core/base-service';
import { IActor } from '@/models/actor.model';

import axios from 'axios';

export default class ActorService extends BaseService<any> {
  public constructor() {
    super('services/serviceauttech/api/actor');
  }

  public getByProjectId(projectId: string, id: any): Promise<any> {
    return axios.get(`${this.endpointName}/${id}?projectId=${projectId}`);
  }

  public createByProjectId(projectId: string, object: IActor): Promise<any> {
    return axios.post(`${this.endpointName}?projectId=${projectId}`, object);
  }

  public updateByProjectId(id: any, projectId: string, object: IActor): Promise<any> {
    return axios.put(`${this.endpointName}/${id}?projectId=${projectId}`, object);
  }

  public getOptionsByProjectId(projectId: string): Promise<any> {
    return axios.get(`${this.endpointName}/combobox?projectId=${projectId}`);
  }
}
